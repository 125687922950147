<template>
  <div class="editor">
    <div>
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
          name="msgtype"
          label="分享类型"
          colon
          style="border-width: 1px"
      >
        <van-radio-group v-model="messageData.msgtype" direction="horizontal" slot="input">
          <van-radio name="miniprogram">小程序</van-radio>
          <van-radio name="news">图文链接</van-radio>
        </van-radio-group>
      </van-field>

      <!--      <div>-->
      <!--        标题:-->
      <!--      </div>-->
      <van-field
          v-model="title"
          :rows="2"
          type="textarea"
          name="title"
          label="标题"
          colon
          label-width="70"
          placeholder="标题"
          style="border-width: 1px"
      />

      <van-field
          v-model="imgUrl"
          :rows="2"
          type="textarea"
          name="title"
          label="图片"
          colon
          label-width="70"
          placeholder="图片"
          style="border-width: 1px"
      >
        <ImageSelectView :image-list="imageList" slot="input" @onSelect="onImageSelect"></ImageSelectView>
      </van-field>

      <van-field
          name="linkType"
          label="地址类型"
          colon
          style="border-width: 1px"
      >
        <van-radio-group v-model="linkType" direction="horizontal" @change="onLinkTypeChange" slot="input">
          <van-radio name="spu">商品详情</van-radio>
          <van-radio name="brand">品牌首页</van-radio>
        </van-radio-group>
      </van-field>

      <van-field
          v-if="messageData.msgtype==='miniprogram'"
          :rows="2"
          type="textarea"
          v-model="page"
          name="page"
          label="小程序地址"
          colon
          placeholder="小程序地址"
      />

      <van-field
          v-if="messageData.msgtype==='news'"
          v-model="link"
          :rows="2"
          type="textarea"
          name="link"
          label="超链接"
          colon
          placeholder="超链接地址"
      />
      <!-- 通过 validator 进行异步函数校验 -->
      <van-field
          v-if="messageData.msgtype==='news'"
          v-model="desc"
          :rows="4"
          type="textarea"
          name="desc"
          label="说明"
          colon
          placeholder="说明文字"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" @click="onSubmit">提交</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {ImagePreview} from 'vant';
import ImageSelectView from "@/views/corp/cell/image_select_view.vue";
import corpWxUtils from "@/js/corp/corpWxUtils";

export default {
  name: 'ShareEditor',
  components: {ImageSelectView},
  props: {
    spuData: {}
  },
  data() {
    return {
      messageData: {
        msgtype: 'miniprogram',
        text: null,
        miniprogram: null,
        video: null,
        file: null,
        news: null,
        msgmenu: null,
      },
      linkType: 'spu',
      appid: 'wx64e5e4e2784339ae',
      title: '',
      imgUrl: '',
      page: '/pages/index/index.html',
      link: '',
      desc: '',
      imageList: []
    }
  },
  watch: {
    spuData: {
      handler(newV, oldV) {
        this.title = this.spuData.title
        this.imgUrl = this.spuData['small_img']
        this.page = `/pages/index/index.html?id=${this.spuData.id}&from=cm`
        this.link = `https://mob.babyzhiai.net/spu?id=${this.spuData.id}`
        this.imageList = [...this.spuData['head_img'], ...[this.spuData['brand_logo']]]
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onImageSelect(index) {
      this.imgUrl = this.imageList[index]
    },
    onLinkTypeChange() {
      if (this.linkType === 'spu') {
        this.page = `/pages/index/index.html?id=${this.spuData.id}&from=cm`
        this.link = `https://mob.babyzhiai.net/spu?id=${this.spuData.id}&from=cm`
      }
      if (this.linkType === 'brand') {
        this.page = `/pages/index/index.html?bid=${this.spuData.id}&from=cm`
        this.link = `https://mob.babyzhiai.net/brand_home?bid=${this.spuData['brand_id']}&from=cm`
      }

    },
    async onSubmit() {
      if (this.messageData.msgtype === 'miniprogram') {
        this.messageData.miniprogram = {
          appid: this.appid,
          title: this.title,
          imgUrl: this.imgUrl,
          page: this.page
        }
      }
      if (this.messageData.msgtype === 'news') {
        ///todo:生成小程序链接，http的
        let query = ''
        if (this.linkType === 'spu') {
          query = `id=${this.spuData.id}&from=cm`
        }
        if (this.linkType === 'brand') {
          query = `id=${this.spuData['brand_id']}&from=cm`
        }
        let data = {
          app_id: "wx64e5e4e2784339ae",
          path: "/pages/index/index",
          query: query
        }

        const linkData = await this.$corpWxUtils.getMiniUrlLinkAsync(data)
        let _errcode = linkData['errcode']
        let _url_link = linkData['url_link']

        this.messageData.news = {
          title: this.title,
          imgUrl: this.imgUrl,
          link: _url_link
        }
      }
      console.log(this.messageData)
      this.$emit('onSubmit', this.messageData)
    }
  }
}
</script>
<style scoped>
.editor {
  padding: 10px;
  border-radius: 10px;
}
</style>