<template>
  <div>
    <div>
      客户画像
    </div>
    <div>
      <van-cell-group>
        <van-cell title="挚爱账号"
                  :value="currentUserData['mob']"
                  :label="`手机:${currentUserData['mob']}\n\nID:${currentUserData['id']}\n\nunionId:${currentUserData['unionId']}`"
        />
        <van-cell title="状态"
                  :value="getUserStatusStr(currentUserData['userStatus'])"/>
        <van-cell title="最近活跃时间" v-if="currentUserData['activeTime']"
                  :value="utils.dateFormat('yyyy-MM-dd hh:mm',new Date(currentUserData['activeTime']))"/>
        <van-cell title="创建时间" v-if="currentUserData['createTime']"
                  :value="utils.dateFormat('yyyy-MM-dd hh:mm',new Date(currentUserData['createTime']))"/>

        <van-cell title="半年内订单总数" v-if="currentUserData['orderCount']"
                  :value="currentUserData['orderCount']['allOrderNum']"/>
        <van-cell title="半年内订单总金额" v-if="currentUserData['orderCount']"
                  :value="currentUserData['orderCount']['allOrderMoney']"/>

        <van-cell title="最近订单时间" v-if="currentUserData['orderCount']"
                  :value="utils.dateFormat('yyyy-MM-dd hh:mm',new Date(currentUserData['orderCount']['lastOrderTime']))"/>

      </van-cell-group>
    </div>

    <div>
      <van-collapse v-model="listActiveName" :accordion="true">
        <van-collapse-item :title="`订单标签:${currentUserData.tagsOrder.length}`" name="1"
                           v-if="currentUserData.tagsOrder">
          <span v-for="(item,index) in currentUserData.tagsOrder" :key="index" style="padding: 2px">
            <van-tag type="danger">{{ item.tagsName }}</van-tag>
          </span>
        </van-collapse-item>

        <van-collapse-item :title="`品牌标签:${currentUserData.tagsBrand.length}`" name="2"
                           v-if="currentUserData.tagsBrand">
          <span v-for="(item,index) in currentUserData.tagsBrand" :key="index" style="padding: 2px">
            <van-tag type="danger">{{ item.tagsName }}</van-tag>
          </span>
        </van-collapse-item>

        <van-collapse-item :title="`购物车标签:${currentUserData.tagsCat.length}`" name="3"
                           v-if="currentUserData.tagsCat">
           <span v-for="(item,index) in currentUserData.tagsCat" :key="index" style="padding: 2px">
            <van-tag type="primary">{{ item.tagsName }}</van-tag>
          </span>
        </van-collapse-item>

        <van-collapse-item :title="`浏览产品标签:${currentUserData.tagsAccess.length}`" name="4"
                           v-if="currentUserData.tagsAccess">
          <span v-for="(item,index) in currentUserData.tagsAccess" :key="index" style="padding: 2px">
            <van-tag type="primary">{{ item.tagsName }}</van-tag>
          </span>
        </van-collapse-item>


        <van-collapse-item :title="`铺货店铺:${currentUserData.userShopList.length}`" name="5"
                           v-if="currentUserData.userShopList">
          <span v-for="(item,index) in currentUserData.userShopList" :key="index" style="padding: 2px">
            <van-button :icon="`https://api.kumob.cn/public/static/images/${item.shopType}.png`">{{
                item.shopName
              }}</van-button>
          </span>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>
<script>
import utils from "@/js/utils";

export default {
  name: "UserDataView",
  computed: {
    utils() {
      return utils
    }
  },
  props: {
    currentUserData: {}
  },
  data() {
    return {
      listActiveName: "1",
    }
  },
  methods: {
    getUserStatusStr(status) {
      let str = '未知'
//       用户状态：
// 0 待验证,1 已认证,10 通过认证资料待完善,2 待审核,3 已锁定,4 已流失,5 认证未通过
      if (status === 0) {
        str = '待验证'
      }
      if (status === 1) {
        str = '已认证'
      }
      if (status === 10) {
        str = '通过认证资料待完善'
      }
      if (status === 2) {
        str = '待审核'
      }
      if (status === 3) {
        str = '已锁定'
      }
      if (status === 4) {
        str = '已流失'
      }
      if (status === 5) {
        str = '认证未通过'
      }
      return str
    },
  }
}
</script>