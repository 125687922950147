<template>
  <div>
    <CorpNavbar :is-home="true" @onShow="showSearch=true"></CorpNavbar>

    <div style="text-align: center;padding: 4px;">
      <van-loading color="#1989fa" type="spinner" v-if="loading===true"/>
    </div>

    <div v-if="errCode===0">
      <van-tabs v-model="activeUser"
                v-if="currentUserDataList && currentUserDataList.length>0 && currentEntry === 'single_chat_tools' || currentEntry === 'chain_single_chat_tools'">
        <van-tab :title="`用户ID:${currentUserData.userId}`" :name="currentUserData.userId"
                 v-for="(currentUserData,index) in currentUserDataList" :key="index">
          <UserDataView :current-user-data="currentUserData"/>
        </van-tab>
      </van-tabs>
    </div>
    <div v-else>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        {{ errMessage }}
      </van-notice-bar>
    </div>


    <ChatGroupView v-if="currentEntry === 'group_chat_tools'" :context="dataContextResult"
                   :current-chat-group-data="currentChatGroupData"></ChatGroupView>
    <div>
      <van-collapse v-model="listActiveName" :accordion="true">
        <van-collapse-item title="调试信息" name="test1">
          <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="dataConfigResult" name="test1">{{ dataConfigResult }}</van-collapse-item>
            <van-collapse-item title="dataConfigAgentResult" name="test2">{{
                dataConfigAgentResult
              }}
            </van-collapse-item>
            <van-collapse-item title="dataContextResult" name="test3">{{ dataContextResult }}</van-collapse-item>
            <van-collapse-item title="dataExtContact" name="test4">{{ dataExtContact }}</van-collapse-item>
            <van-collapse-item title="dataExtChat" name="test5">{{ dataExtChat }}</van-collapse-item>
            <van-collapse-item title="currentUserDataList" name="test6">{{ currentUserDataList }}</van-collapse-item>
            <van-collapse-item title="currentChatGroupData" name="test7">{{ currentChatGroupData }}</van-collapse-item>
          </van-collapse>
        </van-collapse-item>
      </van-collapse>
    </div>

    <van-popup v-model="showSearch" get-container="#app" style="width: 100%">
      <CorpSpuSearch @onShare="onShareSelect"></CorpSpuSearch>
    </van-popup>


    <!--    <van-dialog v-model="showShare" title="分享信息" show-cancel-button>-->
    <!--      <ShareEditor :spu-data="currentSpuData" @onSubmit="onMessageSubmit"/>-->
    <!--    </van-dialog>-->
    <van-popup v-model="showShare" get-container="#app" style="width: 90%;border-radius: 10px;">
      <ShareEditor :spu-data="currentSpuData" @onSubmit="onMessageSubmit"/>
    </van-popup>

<!--    <div>-->
<!--      <van-button @click="sendMenuMessage">菜单消息</van-button>-->
<!--    </div>-->
  </div>
</template>

<script>

import utils from "@/js/utils";
import UserDataView from "@/views/corp/cell/user_data_view.vue";
import ChatGroupView from "@/views/corp/cell/chat_group_view.vue";
import CorpNavbar from "@/views/corp/cell/corp_navbar.vue";
import CorpSpuSearch from "@/views/corp/pages/corp_spu_search.vue";
import ShareEditor from "@/views/corp/cell/share_editor.vue";

export default {
  name: "CorpUserProfile",
  components: {ShareEditor, CorpSpuSearch, CorpNavbar, ChatGroupView, UserDataView},
  computed: {
    utils() {
      return utils
    }
  },
  data() {
    return {
      errCode: 0,
      errMessage: '',
      loading: false,
      showSearch: false,
      showShare: false,
      currentSpuData: {},
      currentEntry: '',
      sdkVersion: '',
      url: '',
      activeUser: '',
      activeName: '',
      listActiveName: '1',
      extUserId: '',
      chatId: '',
      currentChatGroupData: {},
      currentUserDataList: [{
        "code": 0,
        "activeTime": 1724998912000,
        "corpExtUserId": "wmudiGDwAAAit21ddCU5krN4Lw422nQw",
        "corpFollowUserId": "FengLiGuan...",
        "createTime": 1634695499000,
        "id": "23009",
        "mob": "13812345678",
        "orderCount": {"allOrderMoney": 100.04, "allOrderNum": 2, "lastOrderTime": 1717568520000, "outAreaOrderNum": 0},
        "tagsAccess": [{"tagsName": "护脊书包", "weight": 1}, {
          "tagsName": "水杯",
          "weight": 0.5
        }, {"tagsName": "热水壶", "weight": 0.5}, {"tagsName": "吸管直饮杯", "weight": 0.5}, {
          "tagsName": "双肩背包",
          "weight": 0.5
        }, {"tagsName": "学生书包", "weight": 0.5}, {"tagsName": "双肩包", "weight": 0.5}, {
          "tagsName": "儿童背包",
          "weight": 0.5
        }, {"tagsName": "儿童书包", "weight": 0.5}, {"tagsName": "推车凉席", "weight": 0.5}, {
          "tagsName": "推车清凉垫",
          "weight": 0.5
        }, {"tagsName": "凉席垫", "weight": 0.5}, {"tagsName": "宝宝霜", "weight": 0.5}, {
          "tagsName": "面霜",
          "weight": 0.5
        }, {"tagsName": "润肤霜", "weight": 0.5}, {"tagsName": "护肤霜", "weight": 0.5}, {
          "tagsName": "儿童护肤品",
          "weight": 0.5
        }, {"tagsName": "护唇膏", "weight": 0.5}, {"tagsName": "婴儿唇膏", "weight": 0.5}, {
          "tagsName": "宝宝唇膏",
          "weight": 0.5
        }, {"tagsName": "婴幼儿奶粉", "weight": 0.5}, {"tagsName": "婴配奶粉", "weight": 0.5}, {
          "tagsName": "布书",
          "weight": 0.5
        }, {"tagsName": "宝宝玩具", "weight": 0.5}, {"tagsName": "益智玩具", "weight": 0.5}],
        "tagsBrand": [{"tagsName": "Oneye/丸丫", "weight": 1}, {
          "tagsName": "年衣",
          "weight": 0.5
        }, {"tagsName": "HeyBetter/豪贝", "weight": 0.5}, {
          "tagsName": "BACKCARE",
          "weight": 0.5
        }, {"tagsName": "SHUKIKU", "weight": 0.5}, {
          "tagsName": "安贝儿",
          "weight": 0.5
        }, {"tagsName": "Adore Momi/爱迪妈咪", "weight": 0.5}, {
          "tagsName": "Wyeth/惠氏",
          "weight": 0.5
        }, {"tagsName": "jollybaby", "weight": 0.5}],
        "tagsCat": [{"tagsName": "运动户外", "weight": 1}, {
          "tagsName": "儿童包/背包/箱包",
          "weight": 1
        }, {"tagsName": "学生书包", "weight": 1}, {"tagsName": "清洁洗护", "weight": 1}, {
          "tagsName": "宝宝洗护",
          "weight": 1
        }, {"tagsName": "婴童用品", "weight": 0.5}, {"tagsName": "儿童水杯", "weight": 0.5}, {
          "tagsName": "保温杯",
          "weight": 0.5
        }, {"tagsName": "车床座椅", "weight": 0.5}, {"tagsName": "婴儿推车", "weight": 0.5}, {
          "tagsName": "推车清凉垫",
          "weight": 0.5
        }, {"tagsName": "面霜", "weight": 0.5}, {"tagsName": "润唇膏", "weight": 0.5}, {
          "tagsName": "奶粉尿裤",
          "weight": 0.5
        }, {"tagsName": "奶粉", "weight": 0.5}, {"tagsName": "婴幼儿牛奶粉", "weight": 0.5}, {
          "tagsName": "玩具早教",
          "weight": 0.5
        }, {"tagsName": "响铃/布玩/安抚玩具", "weight": 0.5}, {"tagsName": "宝宝布书", "weight": 0.5}],
        "tagsOrder": [{"tagsName": "马库狮", "weight": 1}, {"tagsName": "饭兜", "weight": 1}, {
          "tagsName": "围嘴",
          "weight": 1
        }, {"tagsName": "纯牛奶", "weight": 1}, {"tagsName": "水牛奶", "weight": 1}, {
          "tagsName": "液态奶",
          "weight": 1
        }, {"tagsName": "常温牛奶", "weight": 1}, {"tagsName": "补钙", "weight": 1}, {
          "tagsName": "液体奶糖",
          "weight": 1
        }],
        "unionId": "oNCgx6S9087bMwyHN6yXxkZtE6vI",
        "updateTime": 1725242564512,
        "userId": "23009",
        "userShopList": [{
          "createTime": 1677809842000,
          "shopId": "9527",
          "shopName": "凤梨罐头的小店",
          "shopType": "TB"
        }, {
          "createTime": 1678946923000,
          "shopId": "1062424019",
          "shopName": "tb_8383869",
          "shopType": "TB"
        }, {
          "createTime": 1678947842000,
          "shopId": "24691839",
          "shopName": "redonyx",
          "shopType": "TB"
        }, {
          "createTime": 1679369413000,
          "shopId": "2176063",
          "shopName": "仙女水果店",
          "shopType": "PDDBJSH"
        }, {
          "createTime": 1679379785000,
          "shopId": "719210",
          "shopName": "小欣欣服饰的小店",
          "shopType": "DY"
        }, {
          "createTime": 1679392369000,
          "shopId": "850836659",
          "shopName": "万念俱灰457",
          "shopType": "KS"
        }, {
          "createTime": 1679395855000,
          "shopId": "2383562051",
          "shopName": "越过无人之境",
          "shopType": "TB"
        }, {
          "createTime": 1679395978000,
          "shopId": "wx7871063aecab250c",
          "shopName": "w丶wdy",
          "shopType": "WXSPH"
        }, {
          "createTime": 1679397751000,
          "shopId": "60f2813d217f7700010109a8",
          "shopName": "开放平台测试店2专卖店",
          "shopType": "XHS"
        }, {"createTime": 1679990434000, "shopId": "1416696383", "shopName": "卡姿兰大眼睛2019", "shopType": "KS"}],
        "userStatus": 1
      }],
      config: {},
      agentConfig: {},
      agentConfigData: {},
      agentConfigResult: '',
      dataConfigResult: {},
      dataConfigAgentResult: {},
      dataContextResult: {},
      dataExtContact: {},
      dataExtChat: {},
    }
  },
  created() {
    this.currentUrl()
    this.currentEntry = 'single_chat_tools'
    this.currentUserDataList = []
    this.errCode = 400
    this.errMessage = '未加载'
    // this.currentUserData = {message: '未加载', code: 400}
    setTimeout(() => {
      this.loading = false
    }, 1000)
  },
  async mounted() {
    this.sdkVersion = this.$corpWxUtils.getVersion();
    this.loading = true
    await this.$corpWxUtils.register(this.url, (configResult) => {
      this.loading = false
      this.dataConfigResult = configResult;
    }, (configAgentResult) => {
      this.loading = false
      //agent配置完成可调用其他接口
      this.dataConfigAgentResult = configAgentResult;
    });

    /**
     * 判断当前进入状态
     * contact_profile 从联系人详情进入 3.0.24
     * chain_single_chat_tools 上下游 从单聊会话的工具栏进入 3.0.24
     * single_chat_tools 从单聊会话的工具栏进入 3.0.24
     * group_chat_tools 从群聊会话的工具栏进入 3.0.24
     * chat_attachment 从会话的聊天附件栏进入 3.1.6
     * single_kf_tools 从微信客服的工具栏进入 3.1.10
     * normal 除以上场景之外进入，例如工作台，聊天会话等 3.0.24
     * @param callBack
     */
    //获取当前环境，客户沟通、群聊
    this.loading = true
    this.$corpWxUtils.getContext((result) => {
      this.loading = false
      this.dataContextResult = result
      if (result.errCode === 0) {
        this.currentEntry = result.entry;
        //单聊会话进入
        if (this.currentEntry === 'single_chat_tools' || this.currentEntry === 'chain_single_chat_tools') {
          this.getCurExternalContact()
        }
        if (this.currentEntry === 'group_chat_tools') {
          this.getCurExternalChat()
        }
      }
    });
  },
  methods: {
    currentUrl() {
      this.url = window.location.protocol + '//' + window.location.host + this.$route.fullPath;
      // this.url = window.location.protocol + '//' + window.location.host+'/';
    },
    getCurExternalContact() {
      this.loading = true
      this.$corpWxUtils.getCurExternalContact((result) => {
        this.loading = false;
        this.dataExtContact = result;
        if (result['errCode'] === 0) {
          this.extUserId = result['userId']
          this.loading = true
          this.$corpWxUtils.searchUser(this.extUserId, (res) => {
            this.loading = false
            if (res.code === 200) {
              if (res.data.list && res.data.list.length > 0) {
                this.currentUserDataList = res.data.list
                // this.currentUserData = res.data.list[0];
                this.errCode = 0
                this.errMessage = ''
              } else {
                this.errCode = 404
                this.errMessage = '此用客户并未与挚爱账号绑定，如需绑定请补充该客户在挚爱的注册手机号'
              }
            }
          })
        } else {
          this.errCode = 400
          this.errMessage = '无法获取该客户的联系人ID'
        }
      });
    },

    getCurExternalChat() {
      this.loading = true
      this.$corpWxUtils.getCurExternalChat((result) => {
        this.loading = false;
        this.dataExtChat = result;
        if (result.errCode === 0) {
          this.chatId = result.chatId;
          this.currentChatGroupData = {
            chat: this.chatId
          }

          if (this.chatId) {
            this.$corpWxUtils.getChatGroupDetail(this.chatId, (res) => {
              if (res.code === 200) {
                this.currentChatGroupData = res.data;
              }
            })
          }
        }
      })
    },
    onShareSelect(spuData) {
      this.showShare = true;
      this.currentSpuData = spuData;
    },
    onMessageSubmit(messageData) {
      this.$corpWxUtils.sendCorpChatMessage(messageData)
    },
    sendTextMessage(spuItem) {
      let data = {
        msgtype: 'text',
        text: {
          content: spuItem.title
        }
      };
      this.$corpWxUtils.sendCorpChatMessage(data)
    },
    sendMiniAppMessage(spuItem) {
      let data = {
        msgtype: 'miniprogram',
        miniprogram: {
          appid: 'wx64e5e4e2784339ae',
          title: spuItem.title,
          imgUrl: spuItem['small_img'],
          page: `/pages/index/index.html?id=${spuItem.id}`
        }
      };
      console.log(data)
      this.$corpWxUtils.sendCorpChatMessage(data)
    },
    sendLinkMessage(spuItem) {
      let data = {
        msgtype: 'news',
        news: {
          link: `https://www.babyzhiai.net/user/product/item-id-${spuItem.id}.aspx`,
          title: spuItem.title,
          imgUrl: spuItem['small_img'],
          desc: ``
        }
      }
      this.$corpWxUtils.sendCorpChatMessage(data)
    },
    sendMenuMessage() {
      let data = {
        msgtype: 'msgmenu',
        msgmenu: {
          head_content: "起始文本",
          list: [
            {
              type: 'click',
              click: {
                id: "menu_1",
                content: "菜单1"
              }
            },
            {
              type: 'view',
              view: {
                url: "https://h5.babyzhiai.net/",
                content: "菜单2"
              }
            },
            // {
            //   type: 'miniprogram',
            //   miniprogram: {
            //     appid: 'wx64e5e4e2784339ae',
            //     page: '/pages/index/index.html',
            //     content: '菜单3'
            //   }
            // }
          ]
        }
      }
      this.$corpWxUtils.sendCorpChatMessage(data)
    }
  }
}
</script>