<template>
  <div>
    <van-nav-bar
        :left-arrow="!isHome"
        title="助手"
        right-text="功能"
        :fixed="true"
        :placeholder="true"
        @click-left="onClickLeft"
    >
      <van-icon name="apps-o" size="28" slot="right" @click="showMenu=true"/>
    </van-nav-bar>
    <van-action-sheet v-model="showMenu"
                      :actions="actions"
                      cancel-text="取消"
                      close-on-click-action
                      @select="onActionSelect"/>
  </div>
</template>
<script>
import router from "@/router";

export default {
  name: 'CorpNavbar',
  props: {
    isHome: {type: Boolean, default: false},
  },
  data() {
    return {
      showMenu: false,
      actions: [{name: '商品推荐', subname: '选择推荐的商品'}],
    }
  },
  methods: {
    onClickLeft() {
      router.back();
    },
    onActionSelect(action, index) {
      console.log(index)
      if (index === 0)
        this.$emit("onShow", true)
      // if (index === 1)
      //   this.$utils.goto("/corp_user_order")
    }
  }
}
</script>