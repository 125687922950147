<template>
  <div>
    <van-grid :border="false" :column-num="3">
      <van-grid-item v-for="(image, index) in imageList" :key="index">
        <van-image :src="image" :class="isSelect(index)" @click="onSelect(index)"/>
      </van-grid-item>
    </van-grid>
  </div>
</template>
<script>
export default {
  name: 'ImageSelectView',
  props: {
    imageList: {type: Array},
    // selectedIndex: {type: Number, default: 0},
  },
  data() {
    return {
      selectedIndex: 0,
    }
  },
  methods: {
    onSelect(index) {
      this.selectedIndex = index;
      this.$emit('onSelect', this.selectedIndex);
    },
    isSelect(index) {
      if (this.selectedIndex === index) {
        return 'selected'
      } else {
        return ''
      }
    }
  }
}
</script>
<style>
.selected {
  border: 4px solid #0570db;
  border-radius: 8px;
}
</style>