<template>
  <div class="corp-spu-search">
    <div class="fixed-search">
      <van-search v-model="params.key" shape="round" placeholder="请输入搜索关键词"
                  @search="onSearch"/>
      <van-dropdown-menu>
        <van-dropdown-item v-model="sortValue" :options="sortOption1" @change="onSortSelect"/>
      </van-dropdown-menu>
    </div>
    <div class="search-content">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="search-result-info">
          共{{ total }}条记录
          <div style="text-align: center;padding: 4px;">
            <van-loading color="#1989fa" type="spinner" v-if="loading"/>
          </div>
        </div>
        <div class="product-list">
          <div v-for="(item,index) in list" :key="index">
            <!--            <van-divider/>-->
            <van-card
                :tag="(item['maxDiscount']*100).toFixed(0)+'折'"
                :price="getSpuPrice(item)"
                :origin-price="(item['minUserPrice']/item['maxDiscount']).toFixed(2)"
                :desc="item['sub_title']"
                :title="item.title"
                :thumb="item['small_img']"
            >
              <template #tags>
                <span v-for="(tag,i) in item['lable']" :key="i" style="padding-left: 2px">
                  <van-tag round type="danger">{{ tag['product_lable'] }}</van-tag>
                </span>
              </template>
              <template #footer>
                <van-button size="mini" type="info" @click="onShareMessage(item)">分享给客户</van-button>
              </template>
            </van-card>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CorpSpuSearch',
  data() {
    return {
      loading: false,
      finished: false,
      total: 0,
      params: {
        userId: 23009,
        key: '',
        page: 1,
        size: 20,
        sortArray: [{field: "_score", order: "desc"}]
      },
      list: [],
      sortValue: "a_1",
      sortOption1: [
        {text: '综合排序', value: 'a_1'},
        {text: '最新上线', value: 'a_2'},
        {text: '销量排序', value: 'b_1'},
        {text: '价格降序', value: 'c_1'},
        {text: '价格升序', value: 'c_2'},
      ],
    }
  },
  mounted() {
    this.searchSpu()
  },
  methods: {
    searchSpu() {
      this.loading = true;
      this.$corpWxUtils.searchSpu(this.params, (res) => {
        this.loading = false;
        if (this.params.page === 1) {
          this.list = []
        }
        if (res.code === 200) {
          let _list = res.data.list
          this.list.push(..._list)
          this.total = res.data.total
          this.finished = this.list.length >= this.total
        }
      })
    },
    onLoad() {
      if (!this.finished) {
        this.params.page += 1
        this.searchSpu()
      }
    },
    onSearch() {
      this.params.page = 1
      this.finished = false
      this.searchSpu()
    },
    onSortSelect(value) {
      if (value === 'a_1') {
        this.params.sortArray = [{field: "_score", order: "desc"}]
      }
      if (value === 'a_2') {
        this.params.sortArray = [{field: "createTime", order: "desc"}]
      }
      if (value === 'b_1') {
        this.params.sortArray = [{field: "saleInfo.sale_count", order: "desc"}]
      }
      if (value === 'c_1') {
        this.params.sortArray = [{field: "skus.market_price", order: "desc"}]
      }
      if (value === 'c_2') {
        this.params.sortArray = [{field: "skus.market_price", order: "asc"}]
      }
      this.onSearch()
    },
    getSpuPrice(spuItem) {
      let bestPrice = 0.00
      if (spuItem['priceList']) {
        for (let i in spuItem['priceList']) {
          let data = spuItem['priceList'][i]
          let price = data['promotionPrice']
          if ((price !== 0 && price < bestPrice) || bestPrice === 0) {
            bestPrice = price;
          }
        }
      }
      return bestPrice
    },
    onShareMessage(spuItem) {
      this.$emit('onShare', spuItem)
    }
  }
}
</script>

<style scoped>
.corp-spu-search {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.fixed-search {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

.search-content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 20px;
}

.search-result-info {
  padding-left: 10px;
}

.product-list {
  padding: 0 10px;
}
</style>