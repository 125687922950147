<template>
  <div v-if="currentChatGroupData">
    <div>
      群聊信息
    </div>
    <div v-if="currentChatGroupData['errcode']===0">
      <van-cell :title="currentChatGroupData['group_chat'].name"/>
      <van-cell title="创建时间" v-if="currentChatGroupData['group_chat']['create_time']"
                :value="utils.dateFormat('yyyy-MM-dd hh:mm',new Date(currentChatGroupData['group_chat']['create_time']*1000))"/>

      <van-cell title="管理员" v-if="currentChatGroupData['group_chat']['admin_list']"
                :value="currentChatGroupData['group_chat']['admin_list'].length"/>

      <van-cell title="成员人数" v-if="currentChatGroupData['group_chat']['member_list']"
                :value="currentChatGroupData['group_chat']['member_list'].length"/>
    </div>
    <div v-else>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        {{ currentChatGroupData['errmsg'] }}
      </van-notice-bar>
    </div>
  </div>
</template>
<script>
import utils from "@/js/utils";

export default {
  name: 'ChatGroupView',
  computed: {
    utils() {
      return utils
    }
  },
  props: {
    context: {},
    currentChatGroupData: {},
  }
}
</script>