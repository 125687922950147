<template>
  <div>
    <CorpNavbar></CorpNavbar>
    <div>
      客户订单
    </div>
    <div>
      开发中...
    </div>
  </div>
</template>
<script>
import CorpNavbar from "@/views/corp/cell/corp_navbar.vue";

export default {
  name: 'CorpUserOrder',
  components: {CorpNavbar},
}
</script>